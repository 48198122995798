
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { useI18n } from "vue-i18n";

import { defineComponent, onMounted, ref } from "vue";
import { Html5Qrcode } from "html5-qrcode";
import { modalShowListener } from "@/core/directive/function/common";

export default defineComponent({
  name: "qrcode-scanner",
  props: {
    modalReader: {
      type: String,
      default: "modal_qrcode_scanner",
    },
    reader: {
      type: String,
      default: "qr-code-full-region",
    },
    fps: {
      type: Number,
      default: 10,
    },
    qrbox: {
      type: Number,
      default: 200,
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, context) {
    const { t } = useI18n();
    let html5QrCode: any = null;

    const qrcodeScannerModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const formData = ref({
      lastDecodedText: "",
    });

    const rules = ref({});

    const getCameras = () => {
      loading.value = true;
      Html5Qrcode.getCameras()
        .then((devices) => {
          console.log(devices);
          if (devices && devices.length) {
            html5QrCode = new Html5Qrcode(props.reader);
            start();
            loading.value = false;
          }
        })
        .catch((err: any) => {
          loading.value = false;
          // handle err
          html5QrCode = new Html5Qrcode(props.reader);
          // this.$toast('您需要授予相机访问权限')
        });
    };

    const start = () => {
      html5QrCode
        .start(
          // environment后置摄像头 user前置摄像头
          { facingMode: "environment" },
          {
            fps: props.fps, // 可选，每秒帧扫描二维码
            qrbox: props.qrbox, // 可选，如果你想要有界框UI
            // aspectRatio: 1.777778 // 可选，视频馈送需要的纵横比，(4:3--1.333334, 16:9--1.777778, 1:1--1.0)传递错误的纵横比会导致视频不显示
          },
          onScanSuccess
        )
        .catch((err: any) => {
          // console.log("扫码错误信息", err);
          // 错误信息处理仅供参考，具体情况看输出！！！
          if (typeof err == "string") {
            // this.$toast(err)
          } else {
            // if (err.name == 'NotAllowedError') return this.$toast("您需要授予相机访问权限")
            // if (err.name == 'NotFoundError') return this.$toast('这个设备上没有摄像头')
            // if (err.name == 'NotSupportedError') return this.$toast('摄像头访问只支持在安全的上下文中，如https或localhost')
            // if (err.name == 'NotReadableError') return this.$toast('相机被占用')
            // if (err.name == 'OverconstrainedError') return this.$toast('安装摄像头不合适')
            // if (err.name == 'StreamApiNotSupportedError') return this.$toast('此浏览器不支持流API')
          }
        });
    };

    const stop = () => {
      loading.value = false;
      html5QrCode
        ?.stop()
        .then((ignore: any) => {
          // QR Code scanning is stopped.
          console.log("QR Code scanning stopped.");
        })
        .catch((err: any) => {
          // Stop failed, handle it.
          console.log("Unable to stop scanning.");
        });
    };

    const onScanSuccess = (decodedText, decodedResult) => {
      // Handle the scanned code as you like, for example:
      if (formData.value.lastDecodedText !== decodedText) {
        formData.value.lastDecodedText = decodedText;
        context.emit("update-list", decodedText);
        handleDiscard();
      }
      // console.log(`Code matched = ${decodedText}`, decodedResult);
    };

    const onScanError = (decodedText, decodedResult) => {
      // Handle the scanned code as you like, for example:
      // console.log(`Code matched = ${decodedText}`, decodedResult);
    };

    const init = () => {
      formData.value.lastDecodedText = "";
      getCameras();
    };

    const resetForm = () => {
      stop();
      context.emit("reset-form");
    };

    const updateList = () => {
      handleDiscard();
      context.emit("update-list");
    };

    onMounted(() => {
      modalShowListener(qrcodeScannerModalRef.value, () => {
        init();
      });
      modalHideListener(qrcodeScannerModalRef.value, () => {
        resetForm();
      });
    });

    const handleDiscard = () => {
      hideModal(qrcodeScannerModalRef.value);
    };

    return {
      t,
      props,
      loading,
      formData,
      rules,
      qrcodeScannerModalRef,
      handleDiscard,
      resetForm,
      updateList,
    };
  },
});
