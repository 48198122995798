import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79cfc330"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-data-box w-100 d-flex flex-column align-items-center justify-content-center py-6" }
const _hoisted_2 = {
  key: 1,
  class: "mt-1"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "text-gray-400"
}
const _hoisted_5 = ["data-bs-target"]
const _hoisted_6 = {
  key: 0,
  class: "svg-icon svg-icon-2"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "svg-icon svg-icon-2"
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.img)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["svg-icon m-0", _ctx.imgClass])
        }, [
          _createVNode(_component_inline_svg_icon, { src: _ctx.img }, null, 8, ["src"])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.title || _ctx.subTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          (_ctx.subTitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.subTitle), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.actionModal)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.actionIcon || _ctx.actionLabel)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn btn-primary text-nowrap mt-4",
                "data-bs-toggle": "modal",
                "data-bs-target": '#' + _ctx.actionModal,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.actionClick && _ctx.actionClick(...args)))
              }, [
                (_ctx.actionIcon)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createVNode(_component_inline_svg_icon, { src: _ctx.actionIcon }, null, 8, ["src"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.actionLabel)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.actionLabel), 1))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_5))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
          (_ctx.actionIcon || _ctx.actionLabel)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn btn-primary text-nowrap mt-4",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.actionClick && _ctx.actionClick(...args)))
              }, [
                (_ctx.actionIcon)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                      _createVNode(_component_inline_svg_icon, { src: _ctx.actionIcon }, null, 8, ["src"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.actionLabel)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.actionLabel), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}