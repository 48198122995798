
import { defineComponent } from "vue";

export default defineComponent({
  name: "empty-data-action",
  emits: ["action-click"],
  props: {
    img: {
      type: String,
      required: true,
    },
    imgClass: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    actionModal: {
      type: String,
      default: "",
    },
    actionIcon: {
      type: String,
      default: "",
    },
    actionLabel: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const actionClick = () => {
      context.emit("action-click");
    };

    return {
      actionClick,
    };
  },
});
