
import { defineComponent } from "vue";
import SalesOrderDraftOrderOverview from "./CommonOverview.vue";

export default defineComponent({
  name: "sales-order-draft-order-overview",
  components: {
    SalesOrderDraftOrderOverview,
  },
});
