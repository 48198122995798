import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f7a126c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-475px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "modal-body py-10 min-h-300px" }
const _hoisted_9 = ["id"]
const _hoisted_10 = { class: "modal-footer flex-center" }
const _hoisted_11 = { class: "svg-icon svg-icon-3 ms-0 me-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: _ctx.modalReader,
    ref: "qrcodeScannerModalRef",
    tabindex: "-1",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "modal-header",
          id: 'kt_' + _ctx.modalReader + '_header'
        }, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t("common.scan")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ], 8, _hoisted_4),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", { id: _ctx.reader }, null, 8, _hoisted_9)
        ])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            type: "button",
            class: "btn btn-light me-3",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDiscard && _ctx.handleDiscard(...args)))
          }, [
            _createElementVNode("span", _hoisted_11, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.t("common.cancel")), 1)
          ])
        ])
      ])
    ])
  ], 8, _hoisted_1))
}