
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUpdated,
  ref,
} from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { CommonFormType, CommonHtmlType } from "@/core/directive/type/common";
import {
  CommonArrayToString,
  modalShowListener,
  removeReadonly,
} from "@/core/directive/function/common";
import { ApiBase } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import {
  draftOrderOverview,
  DraftOrderOverview,
  relationItemDraft,
} from "@/core/directive/interface/salesOrder";
import { ElUpload } from "element-plus/es/components/upload";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getDiscountPercent,
  getOrderGrossPrice,
} from "@/core/directive/function/order";
import QrcodeScanner from "@/components/layout/QrcodeScanner.vue";
import WindowResize from "@/components/layout/WindowResize.vue";
import accounting from "accounting-js";

interface File {
  file_upload_record_id: number;
  url: string;
}

export default defineComponent({
  name: "draft-orders-order-overview-add-sku-modal",
  props: {
    form: {
      type: Object as () => DraftOrderOverview,
      default: () => {
        return Object.assign({}, draftOrderOverview);
      },
    },
  },
  emits: ["update-list", "reset-form"],
  components: { QrcodeScanner, WindowResize },
  setup(props, { emit }) {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const draftOrderOverviewAddSkuRef = ref<CommonHtmlType>(null);
    const uploadRef = ref<InstanceType<typeof ElUpload>>();
    const fileList = ref<Array<File>>([]);
    const uploadList = ref<Array<any>>([]);
    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });
    const uploadLoading = ref<boolean>(false);

    const formData = ref({
      brand_id: "",
      brand_name: "",
      name: "",
      brand_article_no: "",
      base_price: 0.01,
      sizeType: 0,
      size_label: "",
      color_label: "",
      gallery: [],
      file_upload_record_id: [] as number[],
      source_link: "",
      remark: "",
    });

    const rules = ref({
      brand_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      brand_article_no: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      base_price: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      // size_label: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      color_label: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      file_upload_record_id: [
        {
          required: false,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value.length === 0 && props.form.config_id == "2") {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    const options = ref({
      brandLoading: false,
      brand: [] as TaggingItem[],
      brandMap: new Map([]),
    });

    const scanResult = (value) => {
      formData.value.brand_article_no = value;
    };

    const showProductModal = () => {
      hideModal(draftOrderOverviewAddSkuRef.value);
    };

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const getBrandSourceData = async (value) => {
      options.value.brandLoading = true;
      let params = {
        search_value: value,
      };
      const { data } = await ApiBase.getBrandSourceData(params);
      options.value.brandLoading = false;
      if (data.code == 0) {
        options.value.brandMap.clear();
        options.value.brand = data.data;
        data.data.map((item: TaggingItem) => {
          options.value.brandMap.set(item.value, item);
        });
        if (value && options.value.brandMap.has(formData.value.brand_id)) {
          const taggingItem = options.value.brandMap.get(
            formData.value.brand_id
          ) as TaggingItem;
          formData.value.brand_name = taggingItem.label;
        }
      }
    };

    const debounceBrandSourceSearch = _.debounce(getBrandSourceData, 1000);

    const searchBrandSourceItems = (query: string) => {
      debounceBrandSourceSearch(query);
    };

    const brandChange = (e) => {
      if (e && options.value.brandMap.has(e)) {
        const taggingItem = options.value.brandMap.get(e) as TaggingItem;
        formData.value.brand_name = taggingItem.label;
      }
    };

    const onUploadChange = async (file, files) => {
      uploadList.value.push(file);
      const params = new FormData();
      let fileArr: any[] = [];
      uploadList.value.map((item) => {
        if (item.raw) {
          fileArr.push(item);
          params.append("file", item.raw, item.raw.name);
        }
      });
      debounceUpload(params, fileArr);
    };

    const uploadImages = async (params, files: any[]) => {
      uploadLoading.value = true;
      const { data } = await ApiBase.ossupload(params);
      uploadLoading.value = false;
      if (data.code === 0) {
        formData.value.file_upload_record_id = [data.data.file_id];
        uploadList.value = [];
        fileList.value = [
          {
            file_upload_record_id: data.data.file_id,
            url: data.data.file_url,
          },
        ];
      } else {
        showServerErrorMsg(data);
        uploadList.value = [];
        files.forEach((item) => {
          uploadRef.value?.handleRemove(item, item.raw);
        });
      }
    };

    const debounceUpload = _.debounce(uploadImages, 100);

    const onUploadError = async (error, file, files) => {
      uploadList.value = [];
      files.forEach((item) => {
        uploadRef.value?.handleRemove(item, item.raw);
      });
    };

    const onUploadExceed = (files, uploadFiles) => {
      Swal.fire({
        title: "",
        text: t("common.uploadFiles", [1]),
        icon: "error",
      });
    };

    const handleRemove = (file) => {
      fileList.value.forEach((item, index) => {
        if (item.file_upload_record_id == file.file_upload_record_id) {
          fileList.value.splice(index, 1);
          uploadList.value.splice(index, 1);
        }
      });
      let fileIds: Array<number> = [];
      fileList.value.forEach((item) => {
        fileIds.push(item.file_upload_record_id);
      });
      formData.value.file_upload_record_id = fileIds;
    };

    const handlePreview = (file) => {
      let arr: string[] = [],
        number = 0;
      fileList.value.forEach((item, index) => {
        arr.push(item.url);
        if (item.file_upload_record_id == file.file_upload_record_id) {
          number = index;
        }
      });
      currentInstance.proxy.emitter.emit("preview-images", {
        sources: arr,
        sourcesIndex: number,
        sourcesSlide: number + 1,
      });
    };

    const isDisabledSubmit = computed(() => {
      let flag = false;
      if (uploadLoading.value) {
        flag = true;
      }
      return flag || loading.value;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          createDraftOrderOcpsSku();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const createDraftOrderOcpsSku = async () => {
      // loading.value = true;
      // const { data } = await ApiDraftOrders.createDraftOrderOcpsSku({
      //   store_id: props.form.store_id,
      //   ...formData.value,
      // });
      // if (data.code === 0) {
      //   const defaultProduct = Object.assign({}, relationItemDraft);
      //   const info = data.data;
      //   info.item_id = 0;
      //   info.sales_price = Number(info.base_price);
      //   info.discount = 100;
      //   info.discount_type = CurrencyType.Percent;
      //   info.assign_ocps_sku_no = info.sku;
      //   info.size_label = info.size_name;
      //   info.color_label = info.color_name;
      //   info.brand = info.brand_name;
      //   info.brand_article_no = info.offi_atk_numb;
      //   const newProduct = Object.assign(defaultProduct, info);
      //   emit("update-list", [newProduct]);
      //   hideModal(draftOrderOverviewAddSkuRef.value);
      // } else {
      //   showServerErrorMsg(data);
      // }
      const info = formData.value;
      const sizeData =
        formData.value.sizeType === 0
          ? {
              size_label: info.size_label,
              range_size: "",
            }
          : {
              range_size: info.size_label,
              size_label: "default",
            };
      const defaultProduct = Object.assign({}, relationItemDraft);
      let otherParams = {};
      if (props.form.config_id == "2") {
        otherParams = {
          discount: -13.44,
          // base_gross_price: getOrderGrossPrice(info.base_price, 0),
          tax_rate: 0,
          net_price: accounting.toFixed(
            (getDiscountPercent(-13.44) / 100) * info.base_price,
            2
          ),
        };
      }
      const newProduct = Object.assign(defaultProduct, {
        brand_id: info.brand_id,
        brand: info.brand_name,
        name: info.name,
        ...sizeData,
        color_label: info.color_label,
        price: info.base_price,
        base_price: info.base_price,
        net_price: Number(info.base_price),
        // sales_price: Number(info.base_price),
        brand_article_no: info.brand_article_no,
        remark: info.remark,
        image_id:
          formData.value.file_upload_record_id.length > 0
            ? formData.value.file_upload_record_id[0]
            : 0,
        images:
          formData.value.file_upload_record_id.length > 0
            ? [fileList.value[0].url]
            : [],
        source_link: info.source_link,
        ...otherParams,
      });
      emit("update-list", [newProduct]);
      hideModal(draftOrderOverviewAddSkuRef.value);
    };

    const handleDiscard = () => {
      hideModal(draftOrderOverviewAddSkuRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      uploadLoading.value = false;
      uploadList.value = [];
      fileList.value = [];
    };

    const init = () => {
      setTimeout(() => {
        console.log(props);
      }, 0);
    };

    onMounted(() => {
      modalShowListener(draftOrderOverviewAddSkuRef.value, () => {
        // init();
      });
      modalHideListener(draftOrderOverviewAddSkuRef.value, () => {
        resetForm();
      });
      cancelReadonly(false);
    });

    onUpdated(() => {
      // MenuComponent.reinitialization();
    });

    return {
      t,
      CommonArrayToString,
      props,
      draftOrderOverviewAddSkuRef,
      formRef,
      loading,
      formData,
      rules,
      options,
      scanResult,
      showProductModal,
      cancelReadonly,
      uploadRef,
      uploadLoading,
      fileList,
      uploadHeaders,
      onUploadChange,
      onUploadError,
      onUploadExceed,
      handlePreview,
      handleRemove,
      searchBrandSourceItems,
      brandChange,
      submit,
      resetForm,
      handleDiscard,
      isDisabledSubmit,
    };
  },
});
