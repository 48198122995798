
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  useAttrs,
  watch,
} from "vue";
import _ from "lodash";
import md5 from "js-md5";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import accounting from "accounting-js";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import {
  AddressData,
  AddressOption,
  ChannelOption,
  defaultAddressData,
  defaultDoubleSaleAddressData,
  MerchantOption,
  OcpsCustomerItem,
  TaggingItem,
} from "@/core/directive/interface/common";
import { ApiBase, ApiDraftOrders, ApiMerchant } from "@/core/api";
import {
  DoubleSaleDraftOrderOverviewRelationItemDraft,
  DraftOrderOverview,
  draftOrderOverview,
  DraftOrderOverviewRelationItemDraft,
} from "@/core/directive/interface/salesOrder";
import AddProductItemModal from "./AddProductItemModal.vue";
import {
  commonBackToList,
  removeReadonly,
  setModuleBCN,
} from "@/core/directive/function/common";
import {
  getCurrencyTypeOptions,
  getDiscountPercent,
  getOrderItemAmountTotal,
  getOrderItemVatTotal,
} from "@/core/directive/function/order";
import EmptyData from "@/components/layout/EmptyData.vue";
import CommonProduct from "@/components/table/CommonProduct.vue";
import { ProductItemImage } from "@/core/directive/interface/order";
import { NumberOrString } from "@/core/directive/type/common";
import AddSkuModal from "./AddSkuModal.vue";
import { CurrencyType } from "@/core/directive/type/order";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import RangeSizeChange from "@/components/layout/RangeSizeChange.vue";
import { getDraftOrderRouteUrl } from "@/core/directive/function/doubleSaleOrder";
import UpdateShippingFee from "./UpdateShippingFee.vue";
import WindowResize from "@/components/layout/WindowResize.vue";
import { User } from "@/store/modules/AuthModule";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { getOwnerLabel } from "@/core/directive/function/user";
import { TMSCustomerOperatingEntityOption } from "@/core/directive/interface/shipment";

export default defineComponent({
  name: "sales-order-draft-order-overview",
  props: {
    type: {
      type: Number,
      default: 0, // 0: sales order draft orders 1: double sale order draft orders
    },
  },
  components: {
    AddProductItemModal,
    EmptyData,
    CommonProduct,
    AddSkuModal,
    PermissionCommon,
    RangeSizeChange,
    UpdateShippingFee,
    WindowResize,
  },
  setup(props, context) {
    const { t } = useI18n();
    const attrs = useAttrs(); // 接收父路由参数
    const route = useRoute();
    const router = useRouter();
    const currentInstance: any = getCurrentInstance();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const rangeSizeChangeModalRef = ref();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref(true);

    const formData = ref(Object.assign({}, draftOrderOverview));
    const oldData = ref("");
    const changeIndex = ref(0);

    const options = ref({
      rangeSize: "",
      defaultFormData: {
        id: route.params.id,
        config_id: "",
        user_account_name: "",
        deposit_payment_status: "",
        grand_total_base: 0.0,
        base_currency_code: "EUR",
        deposit_amount: 0.0,
        deposit_currency: "CNY",
      } as any,
      ownerSwitch: [] as TaggingItem[],
      companyLoading: false,
      company: [] as TMSCustomerOperatingEntityOption[],
      companyMap: new Map<string, TMSCustomerOperatingEntityOption>([]),
      doubleSaleProductTabIndex: 0, // 0: Published items 1: Deleted Items
      doubleSaleProductTabFilterValue: 0, // 0: All 1: Abnormal cases
      customer: [] as OcpsCustomerItem[],
      customerLoading: false,
      // store: [] as TaggingItem[],
      billingAddressLoading: false,
      billingAddress: [] as AddressOption[],
      shippingAddressLoading: false,
      shippingAddress: [] as AddressOption[],
      country: [] as TaggingItem[],
      isTaxCount: false,
      payment_method: [] as TaggingItem[],
      order_type: [] as TaggingItem[],
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
      discount: getCurrencyTypeOptions(),
      deposit_payment_status: [
        {
          label: t("salesOrder.paymentStatusPending"),
          value: 1,
        },
        {
          label: t("salesOrder.paymentStatusPaid"),
          value: 2,
        },
      ],
      tax_rates: [
        {
          label: "0%",
          value: 0,
        },
        {
          label: "7%",
          value: 7,
        },
        {
          label: "9.1%",
          value: 9.1,
        },
        {
          label: "19%",
          value: 19,
        },
        {
          label: "20%",
          value: 20,
        },
      ],
      merchant: [] as TaggingItem[],
      merchantLoading: false,
      channel: [] as TaggingItem[],
      channelLoading: false,
    });

    const rules = ref({
      config_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      customer_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "billing_address.firstname": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "billing_address.lastname": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "billing_address.country_iso_2": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "billing_address.region": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "billing_address.city": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "billing_address.street": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],

      "billing_address.telephone": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "shipping_address.firstname": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "shipping_address.lastname": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "shipping_address.country_iso_2": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "shipping_address.region": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "shipping_address.city": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "shipping_address.street": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "shipping_address.telephone": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      payment_method: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      deposit_amount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      deposit_payment_status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "relation_item_draft.tax_rate": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      merchant: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const userInfo = ref<User>(store.getters.currentUser);

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
        const data = attrs.draftOverview as DraftOrderOverview;
        formData.value = data;
        if (isDoubleSale.value) {
          if (!loading.value) {
            getCustomerOperatingEntity();
          }
        } else {
          if (data.customer_id && !loading.value) {
            getAddressInfo(data.customer_id as string);
          }
          if (formData.value.merchant) {
            getMerchantSourceData("", formData.value.merchant);
            getSalesChannelsData(formData.value.merchant);
          } else {
            getMerchantSourceData("");
          }
        }
      }
    );

    const isDoubleSale = computed(() => {
      return (
        props.type === 1 &&
        (formData.value.config_id == "2" || formData.value.config_id == "14")
      );
    });

    const isMBDoubleSale = computed(() => {
      return props.type === 1 && formData.value.config_id == "14";
    });

    const changeTab = (value: number) => {
      options.value.doubleSaleProductTabIndex = value;
    };

    const getDefaultOwnerId = computed(() => {
      if (isDoubleSale.value) {
        if (formData.value.order_number === "") {
          return userInfo.value.settlement_default_owner;
        }
        return formData.value.owner_id;
      }
      return formData.value.owner_id
        ? formData.value.owner_id
        : userInfo.value.settlement_default_owner;
    });

    const getCustomerOperatingEntity = async () => {
      const { data } = await ApiBase.getCustomerOperatingEntity({
        owner_id: getDefaultOwnerId.value,
      });
      if (data.code === 0) {
        oldData.value = md5(JSON.stringify(formData.value));
        options.value.defaultFormData = Object.assign({}, formData.value);
        options.value.company = data.data;
        data.data.map((item) => {
          options.value.companyMap.set(item.value, item);
        });
        if (formData.value.customer_id) {
          customerChange(formData.value.customer_id as string);
        } else {
          // customerChange(formData.value.customer_id as string);
          if (formData.value.merchant) {
            getMerchantSourceData("", formData.value.merchant);
            getSalesChannelsData(formData.value.merchant);
          }
        }
      } else {
        showServerErrorMsg(data);
      }
    };

    const getCompanyList = async (search: string) => {
      options.value.companyLoading = true;
      let params = {
        name: search,
        company_id: formData.value.company_id,
      };
      const { data } = await ApiBase.packageCompanyCustomer(params);
      options.value.companyLoading = false;
      if (data.code == 0) {
        options.value.company = data.data;
        // if (data.data.length > 0 && !formData.value.customer_id) {
        //   formData.value.customer_id = data.data[0].value;
        // }
      }
    };
    const debounceCompanySearch = _.debounce(getCompanyList, 1000);
    const searchCompanyItems = (query: string) => {
      debounceCompanySearch(query);
    };

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const getAddressDetail = computed(() => {
      return (addressInfo: AddressData) => {
        let str = "";
        if (addressInfo.firstname) {
          str += addressInfo.firstname;
        }
        if (addressInfo.lastname) {
          if (str) {
            str += " ";
          }
          str += addressInfo.lastname;
        }
        if (addressInfo.telephone) {
          if (str) {
            str += ", ";
          }
          str += addressInfo.telephone;
        }
        if (addressInfo.country_iso_2) {
          if (str) {
            str += " ";
          }
          str += getCountryLabel.value(addressInfo.country_iso_2);
        }
        if (addressInfo.region) {
          if (str) {
            str += ", ";
          }
          str += addressInfo.region;
        }
        if (addressInfo.city) {
          if (str) {
            str += ", ";
          }
          str += addressInfo.city;
        }
        if (addressInfo.street) {
          if (str) {
            str += ", ";
          }
          str += addressInfo.street;
        }
        if (addressInfo.zip_code) {
          if (str) {
            str += ", ";
          }
          str += addressInfo.zip_code;
        }
        return str;
      };
    });

    const getShippingAddressDetail = computed(() => {
      return getAddressDetail.value(formData.value.shipping_address);
    });

    const getBillingAddressDetail = computed(() => {
      return getAddressDetail.value(
        formData.value.billing_address as AddressData
      );
    });

    const addressChange = (e, type: string) => {
      if (type == "billing") {
        if (e) {
          formData.value.billing_address_id = e.value;
          // formData.value.billing_address = Object.assign({}, e.data);
          Object.keys(formData.value.billing_address).forEach((key) => {
            if (e.data[key] != undefined) {
              formData.value.billing_address[key] = e.data[key];
            }
          });
          formData.value.billing_address.country_iso_2 = e.data.country_id;
          formData.value.billing_address.zip_code = e.data.postcode;
          sameAddressChange();
        } else {
          formData.value.billing_address_id = "";
          formData.value.billing_address = Object.assign(
            {},
            defaultAddressData
          );
        }
      } else {
        if (e) {
          formData.value.shipping_address_id = e.value;
          // formData.value.shipping_address = Object.assign({}, e.data);
          Object.keys(formData.value.shipping_address).forEach((key) => {
            if (e.data[key] != undefined) {
              formData.value.shipping_address[key] = e.data[key];
            }
          });
          formData.value.shipping_address.country_iso_2 = e.data.country_id;
          formData.value.shipping_address.zip_code = e.data.postcode;
        } else {
          formData.value.shipping_address_id = "";
          formData.value.shipping_address = Object.assign(
            {},
            defaultAddressData
          );
        }
      }
    };

    const sameAddressChange = () => {
      if (formData.value.isSameBillingAddress) {
        formData.value.shipping_address_id = formData.value.billing_address_id;
        formData.value.shipping_address = Object.assign(
          {},
          formData.value.billing_address
        ) as AddressData;
        formData.value.shippingAddress = Object.assign(
          {},
          formData.value.billingAddress
        );
      }
    };

    const getSalesChannelsData = async (id?: NumberOrString, remark?) => {
      if (remark) {
        formData.value.channel = "";
        options.value.channel = [];
      }
      options.value.channelLoading = true;
      const { data } = await ApiMerchant.getSalesChannelsData({
        max_item: "all",
        merchant_id: id,
        status: "10",
      });
      options.value.channelLoading = false;
      if (data.code == 0) {
        options.value.channel = data.data;
        // if (isDoubleSale.value) {
        //   if (data.data.length > 0 && !formData.value.channel) {
        //     formData.value.channel = data.data[0].value;
        //   }
        //   oldData.value = md5(JSON.stringify(formData.value));
        //   options.value.defaultFormData = Object.assign({}, formData.value);
        // }
      }
    };

    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      options.value.merchantLoading = false;
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getProductItems = computed(() => {
      if (isDoubleSale.value) {
        return formData.value.draft_items;
      }
      return formData.value.relation_item_draft;
    });

    const getDoubleProductNotDeletedItems = computed(() => {
      return getProductItems.value.filter(
        (item) =>
          !(item as DoubleSaleDraftOrderOverviewRelationItemDraft).is_deleted
      );
    });

    const getDoubleMargin = computed(() => {
      return (discount) => {
        return accounting.toFixed(-(100 - Number(discount)), 2);
      };
    });

    const priceChange = (
      e,
      item: DraftOrderOverviewRelationItemDraft,
      type: string
    ) => {
      if (type === "base_gross_price") {
        const original_price = Number(item.base_price),
          discount = Number(item.discount);
        if (item.discount_type === CurrencyType.Amount) {
          item.net_price =
            original_price - discount > 0 ? original_price - discount : 0;
        } else {
          // 高精度值计算
          item.net_price = Number(
            accounting.toFixed(original_price * (discount / 100), 2)
          );
        }
      } else if (type === "base_price") {
        const price = Number(item.net_price),
          original_price = Number(item.base_price);
        if (item.discount_type === CurrencyType.Amount) {
          const discount = original_price - price;
          if (discount < 0) {
            item.discount = 0;
          } else {
            item.discount = discount;
          }
        } else {
          const new_discount = Number(
            ((price / original_price) * 100).toFixed(2)
          );
          item.discount = new_discount;
        }
      } else if (type === "price") {
        const price = Number(item.net_price),
          original_price = Number(item.base_price);
        if (item.discount_type === CurrencyType.Amount) {
          const discount = original_price - price;
          if (discount < 0) {
            item.discount = 0;
          } else {
            item.discount = discount;
          }
        } else {
          const new_discount = Number(
            ((price / original_price) * 100).toFixed(2)
          );
          item.discount = new_discount;
        }
      } else {
        const original_price = Number(item.base_price),
          discount = Number(item.discount);
        if (item.discount_type === CurrencyType.Amount) {
          item.net_price =
            original_price - discount > 0 ? original_price - discount : 0;
        } else {
          // 高精度值计算
          item.net_price = Number(
            accounting.toFixed(original_price * (discount / 100), 2)
          );
        }
      }
    };

    const debouncePriceChange = _.debounce(priceChange, 10);

    const getSkuListItemImages = computed(() => {
      return (item: DraftOrderOverviewRelationItemDraft) => {
        let arr: ProductItemImage[] = [];
        item.images.map((row) => {
          arr.push({
            id: 0,
            origin_url: row,
          });
        });
        return arr;
      };
    });

    const rangeSizeModal = (key) => {
      changeIndex.value = key;
      rangeSizeChangeModalRef.value.initData(
        formData.value.relation_item_draft[key].range_size
      );
    };

    const rangeSizeChange = (value) => {
      formData.value.relation_item_draft[changeIndex.value].range_size = value;
    };

    const removeDoubleItem = (
      item: DoubleSaleDraftOrderOverviewRelationItemDraft
    ) => {
      item.is_deleted = !item.is_deleted;
      // Swal.fire({
      //   text: t("salesOrderOverview.removeSkuTip"),
      //   icon: "warning",
      //   showCancelButton: true,
      //   buttonsStyling: false,
      //   confirmButtonText: t("common.yes"),
      //   cancelButtonText: t("common.no"),
      //   customClass: {
      //     confirmButton: "btn btn-primary",
      //     cancelButton: "btn btn-light",
      //   },
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      //     loading.value = true;
      //     const item = formData.value.relation_item_draft[index];
      //     const { data } =
      //       await ApiDraftOrders.deleteDoubleSaleDraftOrderProductItem({
      //         item_id: item.item_id,
      //       });
      //     loading.value = false;
      //     if (data.code === 0) {
      //       formData.value.relation_item_draft.splice(index, 1);
      //     } else {
      //       showServerErrorMsg(data);
      //     }
      //   }
      // });
    };

    const removeItem = (index: number) => {
      formData.value.relation_item_draft.splice(index, 1);
    };

    const getNetPrice = computed(() => {
      return (grossPrice, tax) => {
        const tax_rate = getTax.value(tax);
        const price = Number(grossPrice) / (1 + tax_rate / 100);
        return Number(price.toFixed(2));
      };
    });

    const getGrossPrice = computed(() => {
      return (item) => {
        const tax_rate = getTax.value(item.tax_rate);
        const price = Number(item.net_price) * (1 + tax_rate / 100);
        return Number(price.toFixed(2));
      };
    });

    const getCNYGrossPrice = computed(() => {
      return (item) => {
        const tax_rate = getTax.value(item.tax_rate);
        const price =
          Number(item.net_price) *
          (1 + tax_rate / 100) *
          Number(formData.value.order_rate);
        return Number(price.toFixed(2));
      };
    });

    const getCNYPrice = computed(() => {
      return (base_price) => {
        const price = Number(base_price) * Number(formData.value.order_rate);
        return Number(accounting.toFixed(price, 2));
      };
    });

    const getTax = computed(() => {
      return (tax_rate) => {
        const taxRate: number = tax_rate ? Number(tax_rate) : 0;
        return taxRate;
      };
    });

    const getItemAmountTotal = computed(() => {
      return (
        confirmedQty: number,
        unitPrice: number,
        discountPrice: number,
        discountType: number,
        taxCount: number,
        isTaxCount = true
      ) => {
        return getOrderItemAmountTotal(
          confirmedQty,
          unitPrice,
          discountPrice,
          discountType,
          taxCount,
          isTaxCount
        );
      };
    });

    const getItemVatTotal = computed(() => {
      return (
        confirmedQty: number,
        unitPrice: number,
        discountPrice: number,
        discountType: number,
        taxCount: number,
        isTaxCount = true
      ) => {
        return getOrderItemVatTotal(
          confirmedQty,
          unitPrice,
          discountPrice,
          discountType,
          taxCount,
          isTaxCount
        );
      };
    });

    const getOrderItemsTotal = computed(() => {
      let count = 0;
      getProductItems.value.map((item) => {
        const price = getGrossPrice.value(item);
        const qty = item.qty;
        if (isDoubleSale.value) {
          if (
            !(item as DoubleSaleDraftOrderOverviewRelationItemDraft).is_deleted
          ) {
            count += getItemAmountTotal.value(
              qty,
              price,
              0,
              2,
              getTax.value(item.tax_rate),
              false
            );
          }
        } else {
          count += getItemAmountTotal.value(
            qty,
            price,
            0,
            2,
            getTax.value(item.tax_rate),
            false
          );
        }
      });
      count += Number(formData.value.base_shipping_amount);
      return count;
    });

    const getCNYOrderItemsTotal = computed(() => {
      let count = 0;
      getProductItems.value.map((item) => {
        const price = getCNYGrossPrice.value(item);
        const qty = item.qty;
        if (isDoubleSale.value) {
          if (
            !(item as DoubleSaleDraftOrderOverviewRelationItemDraft).is_deleted
          ) {
            count += getItemAmountTotal.value(
              qty,
              price,
              0,
              2,
              getTax.value(item.tax_rate),
              false
            );
          }
        } else {
          count += getItemAmountTotal.value(
            qty,
            price,
            0,
            2,
            getTax.value(item.tax_rate),
            false
          );
        }
      });
      count += getCNYPrice.value(formData.value.base_shipping_amount);
      return count;
    });

    const getSubmitSkuIds = computed(() => {
      let ids: NumberOrString[] = [];
      getProductItems.value.map((item) => {
        ids.push(item.product_id);
      });
      return ids;
    });

    const getSubmitSkuList = computed(() => {
      let arr: any[] = [];
      getProductItems.value.map((item) => {
        const infoData = {
          name: item.name_en ? item.name_en : item.name,
          product_id: item.product_id,
          item_id: item.item_id,
          brand: item.brand,
          brand_id: item.brand_id,
          brand_article_no: item.brand_article_no,
          merchant_article_no: item.merchant_article_no,
          assign_ocps_sku_no: item.assign_ocps_sku_no,
          color_label: item.color_label,
          size_label: item.size_label,
          range_size: item.range_size,
          qty: item.qty,
          base_price: item.base_price,
          net_price: item.net_price,
          sales_price: getGrossPrice.value(item),
          currency_sales_price: getCNYGrossPrice.value(item),
          discount: item.discount,
          discount_type: item.discount_type,
          tax_rate: item.tax_rate,
          remark: item.remark,
          currency_code: formData.value.order_currency_code,
          image_id: item.image_id ? item.image_id : 0,
          source_link: item.source_link ? item.source_link : "",
        };
        if (isDoubleSale.value) {
          if (
            !(item as DoubleSaleDraftOrderOverviewRelationItemDraft).is_deleted
          ) {
            arr.push(infoData);
          }
        } else {
          arr.push(infoData);
        }
      });
      return arr;
    });

    const updateProductItem = (product) => {
      let arr: any[] = [];
      product.forEach((item) => {
        if (
          getSubmitSkuIds.value.indexOf(item.product_id) === -1 ||
          item.product_id === 0
        ) {
          arr.push(item);
        }
      });
      formData.value.relation_item_draft.push(...arr);
    };

    const isChangeData = computed(() => {
      const newData = md5(JSON.stringify(formData.value));
      const flag = newData !== oldData.value;
      context.emit("getIsChangeData", flag);
      return flag;
    });

    const customerChange = (e: string) => {
      const item = options.value.companyMap.get(e);
      if (item) {
        formData.value.merchant = item.merchant.id;
        options.value.merchant = [
          {
            label: item.merchant.name,
            value: item.merchant.id,
          },
        ];
        formData.value.channel = item.channel.id;
        options.value.channel = [
          {
            label: item.channel.name,
            value: item.channel.id,
          },
        ];
        if (item.billing_address) {
          Object.keys(item.billing_address).forEach((key) => {
            formData.value.billing_address[key] = item.billing_address[key];
          });
          // formData.value.billing_address = Object.assign({}, item.billing_address, {
          //   address_type: "billing"
          // })
        }
        // Object.keys(formData.value.shipping_address).forEach((key) => {
        //   if (item.shipping_address[key]) {
        //     formData.value.shipping_address[key] = item.shipping_address[key];
        //   }
        // });
      } else {
        formData.value.customer_id = "";
        formData.value.merchant = "";
        options.value.merchant = [];
        formData.value.channel = "";
        options.value.channel = [];
        formData.value.billing_address = Object.assign(
          {},
          defaultDoubleSaleAddressData
        );
      }
    };

    const getOcpsCustomersBillingAddress = async (value: string) => {
      options.value.billingAddressLoading = true;
      const { data } = await ApiBase.getOcpsCustomersBillingAddress({
        user_id: value,
      });
      options.value.billingAddressLoading = false;
      if (data.code == 0) {
        options.value.billingAddress = data.data;
        const defaultAddressData = data.data.filter((item) => {
          return item.is_default === 1;
        });
        if (
          defaultAddressData.length > 0 &&
          !formData.value.isSameBillingAddress &&
          formData.value.relation_item_draft.length === 0
        ) {
          formData.value.billing_address_id = defaultAddressData[0].value;
          Object.keys(formData.value.billing_address).forEach((key) => {
            if (defaultAddressData[0].data[key] != undefined) {
              formData.value.billing_address[key] =
                defaultAddressData[0].data[key];
            }
          });
          formData.value.billing_address.country_iso_2 =
            defaultAddressData[0].data.country_id;
          formData.value.billing_address.zip_code =
            defaultAddressData[0].data.postcode;
          // formData.value.billing_address = Object.assign(
          //   {},
          //   defaultAddressData[0].data
          // );
          formData.value.billingAddress = defaultAddressData[0];
        }
      }
    };

    const debounceBillingAddressSearch = _.debounce(
      getOcpsCustomersBillingAddress,
      1000
    );

    const searchBillingAddressItems = (value: string) => {
      debounceBillingAddressSearch(value);
    };

    const getOcpsCustomersShippingAddress = async (value: string) => {
      options.value.shippingAddressLoading = true;
      const { data } = await ApiBase.getOcpsCustomersShippingAddress({
        user_id: value,
      });
      options.value.shippingAddressLoading = false;
      if (data.code == 0) {
        options.value.shippingAddress = data.data;
        const defaultAddressData = data.data.filter((item) => {
          return item.is_default === 1;
        });
        if (
          defaultAddressData.length > 0 &&
          !formData.value.isSameBillingAddress &&
          formData.value.relation_item_draft.length === 0
        ) {
          formData.value.isSameBillingAddress = false;
          formData.value.shipping_address_id = defaultAddressData[0].value;
          // formData.value.shipping_address = Object.assign(
          //   {},
          //   defaultAddressData[0].data
          // );
          Object.keys(formData.value.shipping_address).forEach((key) => {
            if (defaultAddressData[0].data[key] != undefined) {
              formData.value.shipping_address[key] =
                defaultAddressData[0].data[key];
            }
          });
          formData.value.shipping_address.country_iso_2 =
            defaultAddressData[0].data.country_id;
          formData.value.shipping_address.zip_code =
            defaultAddressData[0].data.postcode;
          formData.value.shippingAddress = defaultAddressData[0];
        }
      }
    };

    const debounceShippingAddressSearch = _.debounce(
      getOcpsCustomersShippingAddress,
      1000
    );

    const searchShippingAddressItems = (value: string) => {
      debounceShippingAddressSearch(value);
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.country = data.data;
      }
    };

    const getCountryLabel = computed(() => {
      return (value) => {
        let str = "";
        options.value.country.map((item) => {
          if (item.value === String(value)) {
            str = item.label;
          }
        });
        return str;
      };
    });

    const getOcpsOrderManagerInitData = async () => {
      const { data } = await ApiDraftOrders.getOrderManagerDraftOrderInitData(
        {}
      );
      if (data.code == 0) {
        let order_type: TaggingItem[] = [],
          payment_method: TaggingItem[] = [];
        Object.keys(data.data.order_type).forEach((item) => {
          order_type.push({
            label: data.data.order_type[item],
            value: item,
          });
        });
        Object.keys(data.data.payment_method).forEach((item) => {
          payment_method.push({
            label: data.data.payment_method[item],
            value: item,
          });
        });
        options.value.order_type = order_type;
        options.value.payment_method = payment_method;
      }
    };

    const getAddressInfo = async (value: string) => {
      loading.value = true;
      Promise.all([
        getOcpsCustomersBillingAddress(value),
        getOcpsCustomersShippingAddress(value),
      ])
        .then(() => {
          loading.value = false;
          if (!isDoubleSale.value) {
            oldData.value = md5(JSON.stringify(formData.value));
            options.value.defaultFormData = Object.assign({}, formData.value);
          }
          cancelReadonly(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateShippingFee = (e) => {
      formData.value.base_shipping_amount = e.amount;
    };

    const isProductEmpty = computed(() => {
      let flag = false;
      if (isDoubleSale.value) {
        const arr = getProductItems.value.filter((item) => {
          return item.product_id !== 0;
        });
        if (arr.length === 0) {
          flag = true;
        } else {
          const filterArr = arr.filter((item) => {
            const currentItem =
              item as DoubleSaleDraftOrderOverviewRelationItemDraft;
            if (options.value.doubleSaleProductTabIndex === 0) {
              if (options.value.doubleSaleProductTabFilterValue === 0) {
                return !currentItem.is_deleted;
              } else {
                return !currentItem.is_deleted && currentItem.in_blacklist;
              }
            } else {
              if (options.value.doubleSaleProductTabFilterValue === 0) {
                return currentItem.is_deleted;
              } else {
                return currentItem.is_deleted && currentItem.in_blacklist;
              }
            }
          });
          if (filterArr.length === 0) {
            flag = true;
          }
        }
      } else {
        flag = getProductItems.value.length === 0;
      }

      return flag;
    });

    const isDisabledSubmit = computed(() => {
      let flag = false;
      if (getProductItems.value.length === 0) {
        flag = true;
      }
      return flag;
    });

    const isDisabledDoubleEdit = computed(() => {
      let flag = false;
      if (formData.value.order_number !== "") {
        flag = true;
      }
      return flag;
    });

    const isDisabledCreate = computed(() => {
      let flag = false;
      if (
        (formData.value.config_id == "1" || formData.value.config_id == "12") &&
        options.value.defaultFormData.deposit_payment_status == "1"
      ) {
        return true;
      }
      return flag;
    });

    const isCanDelete = computed(() => {
      let flag = false,
        default_id = options.value.defaultFormData.config_id,
        deposit_payment_status =
          options.value.defaultFormData.deposit_payment_status;
      if (default_id != "1" && default_id != "12" && !isDoubleSale.value) {
        flag = true;
      } else if (
        (default_id == "1" || default_id == "12") &&
        deposit_payment_status != "2"
      ) {
        flag = true;
      }
      return flag;
    });

    const createDraftOrderGenerateOrder = async () => {
      Swal.fire({
        text: t("salesOrderOverview.generateOrderTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = isDoubleSale.value
            ? await ApiDraftOrders.createDoubleSaleDraftOrderGenerateOrder({
                order_id: route.params.id,
              })
            : await ApiDraftOrders.createDraftOrderGenerateOrder({
                order_id: route.params.id,
              });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              html: isDoubleSale.value
                ? t("salesOrderOverview.doubleSaleGenerateOrderSuccessTip")
                : t("salesOrderOverview.generateOrderSuccessTip", [
                    data.data.order_number,
                  ]),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.okGotIt"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              backToList();
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const deleteDraftOrder = () => {
      Swal.fire({
        text: t("salesOrder.deleteTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiDraftOrders.deleteDraftOrder({
            order_id: route.params.id,
          });
          loading.value = false;
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              backToList();
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          if (isDoubleSale.value) {
            updateDoubleSaleDraftOrder(() => {
              //
            });
          } else {
            updateDraftOrder(() => {
              //
            });
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const savetoSalesProfilling = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          if (isDoubleSale.value) {
            updateDoubleSaleDraftOrder(() => {
              router.replace(
                getDraftOrderRouteUrl(route) +
                  route.params.id +
                  "/sales-profilling"
              );
            });
          } else {
            updateDraftOrder(() => {
              router.replace(
                getDraftOrderRouteUrl(route) +
                  route.params.id +
                  "/sales-profilling"
              );
            });
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const updateDraftOrder = async (callback) => {
      loading.value = true;
      const billing_address = {
        firstname: formData.value.billing_address.firstname,
        lastname: formData.value.billing_address.lastname,
        company: formData.value.billing_address.company,
        // idcard: "",
        // email: "",
        // telephone_code: "",
        telephone: formData.value.billing_address.telephone,
        country_iso_2: formData.value.billing_address.country_iso_2,
        region: formData.value.billing_address.region,
        city: formData.value.billing_address.city,
        // district: "",
        street: formData.value.billing_address.street,
        zip_code: formData.value.billing_address.zip_code,
      };
      const shipping_address = {
        firstname: formData.value.shipping_address.firstname,
        lastname: formData.value.shipping_address.lastname,
        company: formData.value.shipping_address.company,
        // idcard: "",
        // email: "",
        // telephone_code: "",
        telephone: formData.value.shipping_address.telephone,
        country_iso_2: formData.value.shipping_address.country_iso_2,
        region: formData.value.shipping_address.region,
        city: formData.value.shipping_address.city,
        // district: "",
        street: formData.value.shipping_address.street,
        zip_code: formData.value.shipping_address.zip_code,
      };
      const { data } = await ApiDraftOrders.updateDraftOrder({
        order_id: route.params.id,
        // order_type: 0,
        // store_id: formData.value.store_id,
        customer_id: formData.value.customer_id,
        // payment_method: formData.value.payment_method,
        // payment_method: OrderManagerPaymentMethod.WeChatPay,
        // order_type: formData.value.order_type,
        // order_currency: formData.value.order_currency_code,
        deposit_amount: formData.value.deposit_amount,
        deposit_currency: formData.value.deposit_currency,
        deposit_payment_status: formData.value.deposit_payment_status,
        order_items: getSubmitSkuList.value,
        shipping_amount: formData.value.base_shipping_amount,
        // billing_address: formData.value.billing_address,
        billing_address: formData.value.isSameBillingAddress
          ? shipping_address
          : billing_address,
        // shipping_address: formData.value.shipping_address,
        shipping_address: shipping_address,
        merchant: formData.value.merchant,
        channel: formData.value.channel,
        comments: "",
      });
      loading.value = false;
      if (data.code === 0) {
        context.emit("getFormData");
        callback();
      } else {
        showServerErrorMsg(data);
      }
    };

    const updateDoubleSaleDraftOrder = async (callback) => {
      loading.value = true;
      const { data } = await ApiDraftOrders.updateDoubleSaleDraftOrder({
        order_id: route.params.id,
        owner_id: getDefaultOwnerId.value,
        order_items: getSubmitSkuList.value,
        customer_id: formData.value.customer_id,
        shipping_amount: formData.value.base_shipping_amount,
        merchant: formData.value.merchant,
        channel: formData.value.channel,
        billing_address: formData.value.billing_address,
        shipping_address: formData.value.shipping_address,
      });
      loading.value = false;
      if (data.code === 0) {
        context.emit("getFormData");
        callback();
      } else {
        showServerErrorMsg(data);
      }
    };

    const init = () => {
      // loading.value = true;
      // , getOcpsOrderManagerInitData()
      Promise.all([getCountryData()])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const backToList = () => {
      if (route.fullPath.includes("/sales-order/draft-orders")) {
        commonBackToList(router, "/sales-order/draft-orders");
      } else {
        commonBackToList(router, "/double-sale-order/draft-orders");
      }
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      init();
      context.emit("getFormData");
    });

    return {
      t,
      getDiscountPercent,
      rangeSizeChangeModalRef,
      formRef,
      loading,
      formData,
      rules,
      options,
      userInfo,
      isDoubleSale,
      isMBDoubleSale,
      getOwnerLabel,
      getCountryLabel,
      getShippingAddressDetail,
      getBillingAddressDetail,
      getDefaultOwnerId,
      changeTab,
      searchCompanyItems,
      cancelReadonly,
      addressChange,
      sameAddressChange,
      getDoubleProductNotDeletedItems,
      getDoubleMargin,
      priceChange,
      debouncePriceChange,
      rangeSizeModal,
      rangeSizeChange,
      getSkuListItemImages,
      removeDoubleItem,
      removeItem,
      getNetPrice,
      getGrossPrice,
      getCNYGrossPrice,
      getTax,
      getItemAmountTotal,
      getItemVatTotal,
      getOrderItemsTotal,
      getCNYOrderItemsTotal,
      updateProductItem,
      isChangeData,
      searchBillingAddressItems,
      searchShippingAddressItems,
      getSalesChannelsData,
      searchMerchantSourceItems,
      updateShippingFee,
      isProductEmpty,
      isDisabledSubmit,
      isDisabledDoubleEdit,
      isDisabledCreate,
      isCanDelete,
      customerChange,
      createDraftOrderGenerateOrder,
      deleteDraftOrder,
      backToList,
      submit,
      savetoSalesProfilling,
    };
  },
});
